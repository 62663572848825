import { Card, CardHeader, Col } from "reactstrap";
import Chart from "react-google-charts";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SmsDaily: React.FC<any> = ({data}) => {
  const { t } = useTranslation();
  const [graphOptions, setGraphOptions] = useState({});

  const handleSmsDailyGraph = async () => {
    setGraphOptions({
      is3D: true,
      colors: [
        "#61AE41", 
        "#E61010",
        "#A1A2DB",
        "#44A8D7",
        "#CE1F91",
        "#3E01F5",
        "#FA5205",
        "#832F08",
        "#F6C761",
        "#F5A3A3",
        "#AD7102"
      ],
    });
  }

  useEffect(() => {
    handleSmsDailyGraph();
  }, []);

  return (
    <Col xl="6" md="6" sm="6" className="col-xl-25 box-col-3">
      <Card className="earning-card">
        <CardHeader>
          <div>
            <h5>{t('daily_sms_status')}</h5>
          </div>
        </CardHeader>
        <Chart
          chartType="PieChart"
          data={data}
          options={graphOptions}
          width={"100%"}
          height={"400px"}
        />
      </Card>
    </Col>
  );
};

export default SmsDaily;
