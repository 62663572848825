import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import CommonModal from "./CommonModalServices";
import { Formik } from "formik";
import FormValidations from "./ValidationsForm";
import * as Yup from "yup";
import { Badges } from "../../../AbstractElements";
import {
  createService,
  deleteService,
  listServices,
  listServicesForRoot,
  updateService,
  viewServices,
  viewServicesForOwner,
  viewCreatedByRootServices,
} from "../../../Service/service";
import { listGatewaySelect } from "../../../Service/gateway";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { CompanyTypeEnum } from "../../../Enums/company.type.enum";

const ContainerServices = () => {
  const { t } = useTranslation();
  const mainTitle = "Services";
  const base = `${process.env.PUBLIC_URL}/contact/contactlist`;

  const [data, setData] = useState<any>();
  
  const [dataSelect, setDataSelect] = useState<any[]>([]);

  const userData = localStorage.getItem("M2C:userData");
  
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const companyType = userData
    ? JSON.parse(userData)?.userInfo?.company_type
    : null;

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentRowId, setCurrentRowId] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [serviceViewData, setServiceViewData] = useState<any>();
  const [openViewPopup, setOpenViewPopup] = useState(false);

  const fetchData = useCallback(
    async (page: number) => {
      try {
        if (companyType === CompanyTypeEnum.OWNER) {
          const response = await listServicesForRoot(identifier, page, itemsPerPage);
          setData(response.services);
          setTotalRecords(response.totalRecords);
        } else {
          const response = await listServices(identifier, page, itemsPerPage);
          setData(response.services);
          setTotalRecords(response.totalRecords);
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    },
    [identifier, itemsPerPage]
  );

  const fetchGatewaySelect = async () => {
    const responseSelect = await listGatewaySelect();
    setDataSelect(responseSelect);
  };

  const [centred, setCentered] = useState(false);
  
  const centeredToggle = () => {
    setCentered(!centred);

    if (centred) {
      setValidationFormInitialValue({
        name: "",
        pre_paid: false,
        active: false,
        block_sent_without_tariff: false,
        type: "",
        concatenated: false,
        use_rn1: false,
        gateway_identifier: undefined,
        service_identifier: undefined,
        company_identifier: "",
      });
      setIsEditing(false);
      setCurrentRowId(null);
    }
  };

  const finishEdit = () => {
    setValidationFormInitialValue({
      name: "",
      pre_paid: false,
      active: false,
      block_sent_without_tariff: false,
      type: "",
      concatenated: false,
      use_rn1: false,
      gateway_identifier: undefined,
      service_identifier: undefined,
      company_identifier: "",
    });
    setIsEditing(false);
    setCurrentRowId(null);
  }

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const ExcludeConfirm = (
    id: string | undefined,
    identifier: string | undefined
  ) => {
    SweetAlert.fire({
      title: t("alert_title"),
      text: t("alert_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("alert_cancel"),
      confirmButtonText: t("alert_button"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteService(id, identifier)
          .then(() => {
            fetchData(currentPage);
            SweetAlert.fire({
              title: t("alert_confirm_title"),
              text: t("alert_confirm_text_service"),
              icon: "success",
            });
          })
          .catch(() => {
            SweetAlert.fire({
              title: t("alert_confirm_title_error"),
              text: t("alert_confirm_text_service_error"),
              icon: "error",
            });
          });
      }
    });
  };

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleItemClick = (action: any) => {
      if (action === "delete") {
        ExcludeConfirm(row.row.identifier, identifier);
        setDropdownOpen(false);
      } else if (action === "edit") {
        setCurrentRowId(row.row.identifier);
        centeredToggle();
                
        if (companyType === CompanyTypeEnum.OWNER) {
          viewServicesForOwner(identifier, row.row.identifier).then((e: any) => {
            setValidationFormInitialValue(e);
            setIsEditing(true);
          });
        } else {
          viewServices(identifier, row.row.identifier).then((e: any) => {
            setValidationFormInitialValue(e);
            setIsEditing(true);
          });
        }
        setDropdownOpen(false);
      } else if (action === "view") {
        viewCreatedByRootServices(identifier, row.row.identifier).then((e: any) => {
          console.log('VIEW SERVICES: ', e);
          setServiceViewData(e);
          setOpenViewPopup(true);
        });
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        
        {(row.row.created_by === identifier && companyType !== CompanyTypeEnum.OWNER) && (
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => handleItemClick("edit")}>
              {t("edit")}
            </DropdownItem>
            <DropdownItem onClick={() => handleItemClick("delete")}>
              {t("delete")}
            </DropdownItem>
          </DropdownMenu>
        )}

        {(row.row.created_by !== identifier && companyType !== CompanyTypeEnum.OWNER) && (
          <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("view")}>
            {t("view")}
          </DropdownItem>
        </DropdownMenu>
        )}

        {(companyType === CompanyTypeEnum.OWNER) && (
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => handleItemClick("edit")}>
              {t("edit")}
            </DropdownItem>
            <DropdownItem onClick={() => handleItemClick("delete")}>
              {t("delete")}
            </DropdownItem>
          </DropdownMenu>
        )}
      </Dropdown>
    );
  };

  const StatusChip = ({ status }: any) => {
    return status ? (
      <Badges className="badge-light-success" color="transparant">
        {t("active")}
      </Badges>
    ) : (
      <Badges className="badge-light-danger" color="transparant">
        {t("inactive")}
      </Badges>
    );
  };

  const columns = [
    {
      name: t("name"),
      selector: (row: any) => `${row.name}`,
      sortable: true,
    },
    {
      name: t("prepaid"),
      selector: (row: any) => `${row.pre_paid}`,
      sortable: true,
    },
    {
      name: t("type"),
      selector: (row: any) => `${row.type}`,
      sortable: true,
    },
    {
      name: t("Status"),
      selector: (row: any) => row.active,
      cell: (row: any) => <StatusChip status={row.active} />,
      sortable: true,
    },
    {
      name: t("Company"),
      selector: (row: any) => `${row.company.name}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, "dd/MM/yyyy HH:mm")}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const commonData = {
        name: values.name,
        pre_paid: values.pre_paid,
        active: values.active,
        block_sent_without_tariff: values.block_sent_without_tariff,
        type: values.type,
        concatenated: values.concatenated,
        cost: values.cost,
        use_rn1: values.use_rn1,
      };

      console.log('COMMON DATA: ', commonData);

      if (isEditing) {
        const serviceDataUpdate = {
          ...commonData,
        };

        await updateService(serviceDataUpdate, currentRowId, identifier).then(() => {
          fetchData(currentPage);
          centeredToggle();
          finishEdit();
          resetForm();
          toast.success(t("success_service"));
          setSubmitError(false);
        });
      } else {
        const serviceData = {
          ...commonData,
          gateway_identifier: values.gateway_identifier,
          service_identifier: values.service_identifier,
          company_identifier: values.company_identifier ?? identifier,
        };

        await createService(serviceData, identifier).then(() => {
          fetchData(currentPage);
          centeredToggle();
          finishEdit();
          resetForm();
          toast.success(t("success_service"));
          setSubmitError(false);
        });
      }
    } catch (error) {
      console.error("Error saving gateway", error);
      setSubmitError(true);
      toast.error(t("error_service"));
    } finally {
      setLoading(false);
      setIsEditing(false);
      setCurrentRowId(null);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      name: "",
      pre_paid: false,
      active: false,
      block_sent_without_tariff: false,
      type: "",
      concatenated: false,
      use_rn1: false,
      gateway_identifier: undefined,
      service_identifier: undefined,
      company_identifier: userData
        ? JSON.parse(userData)?.userInfo?.company_identifier
        : null,
    });

    const formValidationSchema = Yup.object().shape({
      name: Yup.string().required(t("required_field")),
      pre_paid: Yup.boolean().required(t("required_field")),
      active: Yup.boolean().required(t("required_field")),
      block_sent_without_tariff: Yup.boolean().required(t("required_field")),
      type: Yup.string().required(t("required_field")),
      concatenated: Yup.boolean().required(t("required_field")),
      use_rn1: Yup.boolean().required(t("required_field")),
    })

  useEffect(() => {
    fetchData(currentPage);
    fetchGatewaySelect();
  }, [fetchData, currentPage]);

  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("Configurations")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <div style={{ visibility: "hidden" }}>
                  <CardHeaderCommon title={t("CARDHEADERHIDDEN")} />
                </div>
                <div
                  className="btn-search-companies"
                  style={{ marginTop: "15px" }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      centeredToggle();
                    }}
                  >
                    {t("add_service")}
                  </Button>
                </div>
                <CommonModal
                  size="lg"
                  backdrop="static"
                  centered
                  title={t("add_service_text")}
                  isOpen={centred}
                  toggle={centeredToggle}
                >
                  <div className="modal-toggle-wrapper">
                    <Formik
                      initialValues={validationFormInitialValue}
                      onSubmit={handleSubmit}
                      validationSchema={formValidationSchema}
                      enableReinitialize={true}
                    >
                      {({ errors }) => (
                        <FormValidations
                          submitErrors={submitErrors}
                          setSubmitError={setSubmitError}
                          errors={errors}
                          dataSelect={dataSelect ? dataSelect : []}
                          loading={loading}
                          currentRowId={currentRowId}
                        />
                      )}
                    </Formik>
                  </div>
                </CommonModal>
                {openViewPopup && (
                  <CommonModal
                    size="lg"
                    backdrop="static"
                    centered
                    title={t("view_service")}
                    isOpen={openViewPopup}
                    toggle={() => setOpenViewPopup(!openViewPopup)}
                  >
                    <Card>
                      <CardBody>
                        <div className="content">
                          <div className="row mb-3">
                            <div className="col">
                              <div>
                                <strong>{t("service")}: </strong>
                              </div>
                              <div>
                                <span>{serviceViewData?.name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col">
                              <div>
                                <strong>{t("prepaid")}: </strong>
                              </div>
                              <div>
                                <span>{serviceViewData?.pre_paid ? t("yes") : t("no")}</span>
                              </div>
                            </div>
                            <div className="col">
                              <div>
                                <strong>{t("type")}: </strong>
                              </div>
                              <div>
                                <span>{serviceViewData?.type}</span>
                              </div>
                            </div>
                            <div className="col">
                              <div>
                                <strong>{t("Status")}: </strong>
                              </div>
                              <div>
                                <span>{serviceViewData?.active ? t("active") : t("inactive")}</span>
                              </div>
                            </div>
                            <div className="col">
                              <div>
                                <strong>{t("cost")}: </strong>
                              </div>
                              <div>
                                <span>{serviceViewData?.cost}</span>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col">
                              <div>
                                <strong>{t("Company")}: </strong>
                              </div>
                              <div>
                                <span>{serviceViewData?.company.name}</span>
                              </div>
                            </div>
                            <div className="col">
                              <div>
                                <strong>{t("created_at")}: </strong>
                              </div>
                              <div>
                                <span>{format(new Date(serviceViewData?.created_at), "dd/MM/yyyy HH:mm")}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </CommonModal>
                )}
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecords}
                    paginationPerPage={itemsPerPage}
                    onChangePage={(page) => {
                      setCurrentPage(page);
                      fetchData(page);
                    }}
                    onChangeRowsPerPage={(size) => {
                      setItemsPerPage(size);
                      setCurrentPage(1);
                    }}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerServices;
