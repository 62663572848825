import React, { useState } from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  loading,
  setSubmitError,
}) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("first_name_label_user")}</Label>
            <Field
              name="first_name"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.first_name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("first_name_placeholder_user")}
            />
            <ErrorMessage
              name="first_name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("last_name_label_user")}</Label>
            <Field
              name="last_name"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.last_name ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("last_name_placeholder_user")}
            />
            <ErrorMessage
              name="last_name"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("password_label_user")}</Label>
            <Field
              name="password"
              type={show ? "text" : "password"}
              className={`form-control ${
                submitErrors && `${errors.password ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("password_placeholder_user")}
            />
            <div className="show-hide" onClick={() => setShow(!show)}>
              <span className="show"> </span>
            </div>
            <ErrorMessage
              name="password"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("email_label_user")}</Label>
            <Field
              name="email"
              type="email"
              className={`form-control ${
                submitErrors && `${errors.email ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("email_placeholder_user")}
            />
            <ErrorMessage
              name="email"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("phone_label_user")}</Label>
            <Field name="mobile_phone">
              {({ field }: any) => (
                <InputMask
                  {...field}
                  mask="(99) 99999-9999"
                  maskChar={null}
                  className={`form-control ${
                    submitErrors &&
                    `${errors.mobile_phone ? "is-invalid" : "is-valid"}`
                  }`}
                  placeholder={t("phone_placeholder_user")}
                />
              )}
            </Field>
            <ErrorMessage
              name="mobile_phone"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("type_label_user")}</Label>
            <Field
              as="select"
              name="type"
              className={`form-control ${
                submitErrors && `${errors.type ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value=""></option>
              <option value="root">Root</option>
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </Field>
            <ErrorMessage
              name="type"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="4">
          <FormGroup style={{ display: "flex", flexDirection: "column" }}>
            <Label check>{t("active")}</Label>
            <Label className="switch">
              <Field type="checkbox" name="active" />
              <span className="switch-state" />
            </Label>
            <ErrorMessage
              name="active"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={loading}
          >
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
