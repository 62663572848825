import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import CommonModal from "./CommonModal";
import * as Yup from "yup";
import { Formik } from "formik";
import FormValidations from "./ValidationsForm";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Badges } from "../../../AbstractElements";
import {
  createCampaign,
  deleteCampaign,
  listCampaignByCompany,
  updateCampaign,
  viewCampaign,
} from "../../../Service/campaign";
import { toast } from "react-toastify";
import { CampaignStatusEnum } from "../../../Enums/campaign.status.enum";

const ContainerSMS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const mainTitle = "SMS";
  const base = `${process.env.PUBLIC_URL}/campaigns/sms`;

  const [edit, setEdit] = useState(false);
  const [idEdit, setIdEdit] = useState<any>();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const userData = localStorage.getItem("M2C:userData");

  if (!userData) {
    navigate(`${process.env.PUBLIC_URL}`);
  }

  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const fetchData = useCallback(async () => {
    try {
      const response = await listCampaignByCompany(identifier);
      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [identifier]);

  const [centred, setCentered] = useState(false);
  const centeredToggle = () => setCentered(!centred);

  const StatusChip = ({ status }: any) => {
    switch (status) {
      case CampaignStatusEnum.ACTIVE:
        return (
          <Badges className="badge-light-success btn" color="transparant">
            {t("active")}
          </Badges>
        );
      
      case CampaignStatusEnum.INACTIVE:
        return (
          <Badges className="badge-light-warning btn" color="transparant">
            {t("inactive")}
          </Badges>
        );

      case CampaignStatusEnum.PAUSED:
        return (
          <Badges className="btn-primary btn" color="transparant">
            {t("paused")}
          </Badges>
        );
      
      case CampaignStatusEnum.PENDING:
        return (
          <Badges className="btn-secondary btn" color="transparant">
            {t("pending")}
          </Badges>
        );

      case CampaignStatusEnum.PROCESSING:
        return (
          <Badges className="badge-light-info btn" color="transparant">
            {t("processing")}
          </Badges>
        );

      case CampaignStatusEnum.SENT:
        return (
          <Badges className="btn-success btn" color="transparant">
            {t("sent")}
          </Badges>
        );

      default:
        return (
          <Badges className="badge-light-warning btn" color="transparant">
            {t("inactive")}
          </Badges>
        );
    }
  };

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const ExcludeConfirm = () => {
      SweetAlert.fire({
        title: t("alert_title"),
        text: t("alert_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("alert_cancel"),
        confirmButtonText: t("alert_button"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteCampaign(row.row.identifier, identifier)
            .then(() => {
              fetchData();
              SweetAlert.fire({
                title: t("alert_confirm_title"),
                text: t("alert_confirm_text_campaing"),
                icon: "success",
              });
            })
            .catch(() => {
              SweetAlert.fire({
                title: t("alert_confirm_title_error"),
                text: t("alert_confirm_text_campaing_error"),
                icon: "error",
              });
            });
        }
      });
    };

    const handleItemClick = (action: any) => {
      if (action === "delete") {
        ExcludeConfirm();
        setDropdownOpen(false);
      } else if (action === "edit") {
        setEdit(true);
        setIdEdit(row.row);
        viewCampaign(row.row.identifier, identifier).then((e: any) => {
          setValidationFormInitialValue(e);
        });
        centeredToggle();
        setDropdownOpen(false);
      } else if (action === "access") {
        navigate(
          `${process.env.PUBLIC_URL}/campaigns/sms/access/${row.row.identifier}`
        );
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        {(row.row.status === CampaignStatusEnum.ACTIVE || row.row.status === CampaignStatusEnum.PAUSED) && (
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem onClick={() => handleItemClick("edit")}>
              {t("edit")}
            </DropdownItem>
            <DropdownItem onClick={() => handleItemClick("delete")}>
              {t("delete")}
            </DropdownItem>
            <DropdownItem onClick={() => handleItemClick("access")}>
              {t("access")}
            </DropdownItem>
          </DropdownMenu>
        )}
      </Dropdown>
    );
  };

  const columns = [
    {
      name: t("campaign"),
      selector: (row: any) => `${row.name}`,
      sortable: true,
    },
    {
      name: t("Status"),
      selector: (row: any) => row.status,
      cell: (row: any) => <StatusChip status={row.status} />,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const campaignData = {
        name: values.name,
        company_identifier: identifier,
      };
      edit
        ? await updateCampaign(campaignData, idEdit.identifier, identifier).then(() => {
            fetchData();
            centeredToggle();
            resetForm();
            toast.success(t("success_campaign"));
            setSubmitError(false);
          })
        : await createCampaign(campaignData, identifier).then(() => {
            fetchData();
            centeredToggle();
            resetForm();
            toast.success(t("success_campaign"));
            setSubmitError(false);
          });
    } catch (error) {
      console.error("Error saving gateway", error);
      setSubmitError(true);
      toast.error(t("error_campaign"));
    } finally {
      setLoading(false);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      name: "",
    });

  const formValidationSchema = Yup.object().shape({
    name: Yup.string().required(t("name_alert")),
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const customStyles = {
    table: {
      style: {
        minHeight: "250px",
      },
    },
  };

  return (
    <>
      <Breadcrumbs
        base={base}
        mainTitle={t(mainTitle)}
        parent={t("campaigns")}
      />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <div style={{ visibility: "hidden", marginTop: "20px" }}>
                  <CardHeaderCommon title={t("sms")} />
                </div>
                <div className="btn-search-companies">
                  <Button
                    color="primary"
                    onClick={() => {
                      edit && setEdit(false);
                      centeredToggle();
                    }}
                  >
                    {t("add_campaign")}
                  </Button>
                </div>
                <CommonModal
                  size="lg"
                  backdrop="static"
                  centered
                  title={edit ? t("edit_campaign") : t("add_campaign")}
                  isOpen={centred}
                  toggle={centeredToggle}
                >
                  <div className="modal-toggle-wrapper">
                    <Formik
                      initialValues={validationFormInitialValue}
                      onSubmit={handleSubmit}
                      validationSchema={formValidationSchema}
                      enableReinitialize={true}
                    >
                      {({ errors }) => (
                        <FormValidations
                          submitErrors={submitErrors}
                          setSubmitError={setSubmitError}
                          errors={errors}
                          loading={loading}
                        />
                      )}
                    </Formik>
                  </div>
                </CommonModal>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    customStyles={customStyles}
                    pagination
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerSMS;
