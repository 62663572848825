import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const listServicesForRoot = async (
  id: any,
  page: number = 1,
  recordsPerPage: number = 10
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/company-services/for-root/${id}?page=${page}&limit=${recordsPerPage}`,
      getAuthHeaders()
    );

    return {
      services: response.data.services,
      totalRecords: response.data.totalRecords,
    };
  } catch (error) {
    console.error("Error fetching services", error);
    throw error;
  }
};

const listServices = async (
  id: any,
  page: number = 1,
  recordsPerPage: number = 10
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/company-services/${id}?page=${page}&limit=${recordsPerPage}`,
      getAuthHeaders()
    );

    return {
      services: response.data.services,
      totalRecords: response.data.totalRecords,
    };
  } catch (error) {
    console.error("Error fetching services", error);
    throw error;
  }
};

const listServicesForSelect = async (
  id: any,
): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/company-services/for-select/${id}`,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching services", error);
    throw error;
  }
};

const viewServicesForOwner = async (id: any, id_service: any): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/for-root/${id_service}/${id}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching service", error);
    throw error;
  }
};

const viewServices = async (id: any, id_service: any): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/${id_service}/${id}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching service", error);
    throw error;
  }
};

const viewCreatedByRootServices = async (id: any, id_service: any): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/services/created-by-root/${id_service}/${id}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching service", error);
    throw error;
  }
};

const createService = async (data: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/services/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating service", error);
    throw error;
  }
};

const updateService = async (data: any, identifier: any, companyIdentifier: string): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/services/${identifier}/${companyIdentifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating service", error);
    throw error;
  }
};

const deleteService = async (
  id: string | undefined,
  identifier: string | undefined
): Promise<any> => {
  try {
    const response = await axios.delete(
      `${API_URL}/services/${id}/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting service", error);
    throw error;
  }
};

export {
  listServices,
  listServicesForRoot,
  listServicesForSelect,
  updateService,
  viewServices,
  viewServicesForOwner,
  viewCreatedByRootServices,
  createService,
  deleteService,
};
