import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useState } from "react";
import { createByFileProcon, deleteProcon, listProcon } from "../../../Service/procon";
import { format } from "date-fns";
import SweetAlert from "sweetalert2";
import CommonModal from "../BlackList/CommonModalContactList";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import FormValidationsImport from "./ValidationsFormImport";

const ContainerProcon = () => {
  const { t } = useTranslation();

  const mainTitle = "procon";
  const base = `${process.env.PUBLIC_URL}/contact/procon`;
  const [data, setData] = useState<any>();
  const { id } = useParams<{ id: string; }>();
  const [loading, setLoading] = useState(false);
  const [submitErrors, setSubmitError] = useState<boolean>(false);

  const navigate = useNavigate();

  

  const [modalTitle, setModalTitle] = useState(t("import_contact_title"));

  const [centredImport, setCenteredImport] = useState(false);
  const centeredToggleImport = () => setCenteredImport(!centredImport);

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  useEffect(() => {
    setModalTitle(t("import_contact_title"));
  }, []);

  const [validationFormInitialValueImport] = useState<any>({
    contacts: "",
  });

  const handleSubmitImport = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const contactData = {
        file: values.files,
      };

      const dataToSubmit = contactData;

      console.log('DATA TO SUBMIT FILE CONTACTS PROCON: ', dataToSubmit);

      await createByFileProcon(identifier, dataToSubmit).then(() => {
        fetchData();
        resetForm();
        toast.success(t("success_contact"));
        setSubmitError(false);
      });

      navigate(`${process.env.PUBLIC_URL}/contact/procon`);
    } catch (error) {
      console.error("Error saving contact", error);
      setSubmitError(true);
      toast.error(t("error_contact"));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await listProcon(identifier);
      setData(response);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, []);

  const ColumnAction = (row: any) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const ExcludeConfirm = () => {
      SweetAlert.fire({
        title: t("alert_title"),
        text: t("alert_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("alert_cancel"),
        confirmButtonText: t("alert_button"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteProcon(row.row.identifier, identifier)
            .then(() => {
              fetchData();
              SweetAlert.fire({
                title: t("alert_confirm_title"),
                text: t("alert_confirm_text_contact_list"),
                icon: "success",
              });
            })
            .catch(() => {
              SweetAlert.fire({
                title: t("alert_confirm_title_error"),
                text: t("alert_confirm_text_contact_list_error"),
                icon: "error",
              });
            });
        }
      });
    };

    const handleItemClick = (action: any) => {
      if (action === "delete") {
        ExcludeConfirm();
        setDropdownOpen(false);
      }
    };

    return (
      <Dropdown className="icon-dropdown" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret color="">
          <i className="icon-more-alt" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={() => handleItemClick("delete")}>
            {t("delete")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const columns = [
    {
      name: t("number"),
      selector: (row: any) => `${row.number}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) => `${format(row.created_at, 'dd/MM/yyyy HH:mm')}`,
      sortable: true,
    },
    {
      name: t("actions"),
      cell: (row: any) => <ColumnAction row={row} />,
      sortable: true,
      center: true,
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      <Breadcrumbs base={base} mainTitle={t(mainTitle)} parent={t("procon")} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <CardHeaderCommon title={t("numbers_procon")} />
              </div>
              <div
                className="btn-search-companies"
                style={{ marginTop: "15px"}}
              >
                <Button
                  color="primary"
                  onClick={() => {
                    centeredToggleImport();
                  }}
                  style={{ float: "right" }}
                >
                  {t("import_contact")}
                </Button>
                <CommonModal
                  size="lg"
                  backdrop="static"
                  centered
                  title={modalTitle}
                  isOpen={centredImport}
                  toggle={centeredToggleImport}
                >
                  <div className="modal-toggle-wrapper">
                    <Formik
                      initialValues={validationFormInitialValueImport}
                      onSubmit={handleSubmitImport}
                    >
                      <FormValidationsImport />
                    </Formik>
                  </div>
                </CommonModal>
              </div>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerProcon;
