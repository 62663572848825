import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import CardHeaderCommon from "../../../CommonElements/CardHeaderCommon/CardHeaderCommon";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModalCredits";
import { Formik } from "formik";
import FormValidationsCredits from "./ValidationsFormCredits";
import {
  createTransaction,
  listTransactions,
} from "../../../Service/transaction";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { format } from "date-fns";

const ContainerCredits = () => {
  const { t } = useTranslation();

  const mainTitle = "credits";
  const base = `${process.env.PUBLIC_URL}/clients`;
  const textBase = [{ text: t("company_text") }];

  const userData = localStorage.getItem("M2C:userData");
  const identifier = userData
    ? JSON.parse(userData)?.userInfo?.company_identifier
    : null;

  const { id } = useParams<{ id: string }>();
  const [centred, setCentered] = useState(false);
  const centeredToggle = () => {
    setCentered(!centred);
    if (!centred) {
      setValidationFormInitialValue({});
      setIsEditing(false);
    }
  };

  const [submitErrors, setSubmitError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    type: "",
    direction: "",
    page: 1,
    limit: 10,
  });

  const [totalRecords, setTotalRecords] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const response: any = await listTransactions(id, filters);
      setData(response?.transactions || []);
      setTotalRecords(response?.totalRecords || 0);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }, [id, filters]);

  const columns = [
    {
      name: t("description"),
      selector: (row: any) => `${row.description}`,
      sortable: true,
    },
    {
      name: t("amount"),
      selector: (row: any) => `${row.amount}`,
      sortable: true,
    },
    {
      name: t("company_name"),
      selector: (row: any) => `${row.company_name}`,
      sortable: true,
    },
    {
      name: t("created_at"),
      selector: (row: any) =>
        format(new Date(row.created_at), "dd/MM/yyyy HH:mm"),
      sortable: true,
    },
  ];

  const handleSubmit = async (
    values: any,
    { resetForm }: { resetForm: () => void }
  ) => {
    setLoading(true);
    try {
      const commonData = {
        amount: values.amount,
        description: values.description,
        type: values.type,
        company_identifier: id,
      };

      if (isEditing) {
      } else {
        await createTransaction(commonData, identifier).then(() => {
          fetchData();
          centeredToggle();
          resetForm();
          toast.success(t("success_transaction"));
          setSubmitError(false);
        });
      }
    } catch (error) {
      console.error("Error saving gateway", error);
      setSubmitError(true);
      toast.error(t("error_service"));
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  const [validationFormInitialValue, setValidationFormInitialValue] =
    useState<any>({
      amount: null,
      description: null,
      type: null,
      company_identifier: null,
    });

  const formValidationSchema = Yup.object().shape({
    // company: Yup.string().required(t("name_alert")),
    // credits: Yup.string().required(t("name_alert")),
    // description: Yup.string().required(t("name_alert")),
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="header-filter-companies">
        <div>
          <Label className="me-2">{t("type")}</Label>
          <Input
            type="select"
            value={filters.type}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, type: e.target.value }))
            }
          >
            <option value=""></option>
            <option value="credit">{t("credit")}</option>
            <option value="debit">{t("debit")}</option>
          </Input>
        </div>
        <div>
          <Label className="me-2">{t("direction")}</Label>
          <Input
            type="select"
            value={filters.direction}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, direction: e.target.value }))
            }
          >
            <option value=""></option>
            <option value="ASC">{t("asc")}</option>
            <option value="DESC">{t("desc")}</option>
          </Input>
        </div>
      </div>
    );
  }, [filters, t]);

  return (
    <>
      <Breadcrumbs base={base} mainTitle={t(mainTitle)} parent={t("clients")} />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Card>
              <div className="header-companies">
                <div style={{ visibility: "hidden" }}>
                  <CardHeaderCommon title={t("clients")} span={textBase} />
                </div>
                <div className="btn-search-companies">
                  <Button
                    color="primary"
                    onClick={() => {
                      centeredToggle();
                    }}
                  >
                    {t("add_credits")}
                  </Button>
                </div>
              </div>
              <CommonModal
                size="lg"
                backdrop="static"
                centered
                title={"Adicionar créditos"}
                isOpen={centred}
                toggle={centeredToggle}
              >
                <div className="modal-toggle-wrapper">
                  <Formik
                    initialValues={validationFormInitialValue}
                    onSubmit={handleSubmit}
                    validationSchema={formValidationSchema}
                  >
                    {({ errors }) => (
                      <FormValidationsCredits
                        submitErrors={submitErrors}
                        setSubmitError={setSubmitError}
                        errors={errors}
                        loading={loading}
                      />
                    )}
                  </Formik>
                </div>
              </CommonModal>
              <CardBody>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecords}
                    onChangePage={(page) =>
                      setFilters((prev) => ({ ...prev, page }))
                    }
                    onChangeRowsPerPage={(limit) =>
                      setFilters((prev) => ({ ...prev, limit }))
                    }
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    highlightOnHover
                    striped
                    persistTableHead
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContainerCredits;
