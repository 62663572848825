import { Card, CardBody, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import CommonCardHeaderInitial from "../../../../CommonElements/CommonCardHeaderInitial/CommonCardHeaderInitial";
import { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";

const YearlySmsSpend: React.FC<any> = ({data}) => {
  const { t } = useTranslation();

  const [graphOptionsData, setGraphOptionsData] = useState<ApexOptions | null>(null);

  const title = `${t('year_monthly_messages_cost')} - ${new Date().getFullYear()}`;

  const setGraphOptions = () => {
    const cost = data.map((item: any) => item.cost ?? 0);
  
    const orderBarChartData: ApexOptions = {
      series: [
        {
          name: t('cost'),
          data: cost,
        },
      ],
      chart: {
        type: "bar",
        height: 180,
        toolbar: {
          show: false,
        },
      },
      colors: [
        "#61AE41", 
        "#E61010",
        "#A1A2DB",
        "#44A8D7",
        "#CE1F91",
        "#3E01F5",
        "#FA5205",
        "#832F08",
        "#F6C761",
        "#F5A3A3",
        "#AD7102"
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 0,
          columnWidth: "100%",
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          t('Jan'), 
          t('Feb'), 
          t('Mar'), 
          t('Apr'), 
          t('May'), 
          t('Jun'), 
          t('Jul'), 
          t('Aug'), 
          t('Sep'), 
          t('Oct'), 
          t('Nov'), 
          t('Dec')
        ],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      fill: {
        opacity: 0.7,
      },
      tooltip: {
        enabled: false,
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      responsive: [
        {
          breakpoint: 405,
          options: {
            chart: {
              height: 150,
            },
          },
        },
      ],
    };

    setGraphOptionsData(orderBarChartData)
  }

  
  useEffect(() => {
    setGraphOptions();
  }, [data]);

  return (
    <Col lg="12" md="12">
      <Card className="transaction-history">
        <CommonCardHeaderInitial headClass="pb-0" title={title} mainTitle={true} />
        <CardBody className="pb-0">
          <div className="current-sale-container order-container">
            {graphOptionsData && (
              <ReactApexChart options={graphOptionsData} series={graphOptionsData.series} type="bar" height={350} />
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default YearlySmsSpend;
