import React from "react";
import { ErrorMessage, Field, Form } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";

const FormValidations: React.FC<any> = ({
  errors,
  submitErrors,
  setSubmitError,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <Form className="g-3 needs-validation custom-input">
      <Row>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("value")}</Label>
            <Field
              name="amount"
              type="number"
              className={`form-control ${
                submitErrors && `${errors.amount ? "is-invalid" : "is-valid"}`
              }`}
            />
            <ErrorMessage
              name="amount"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="6">
          <FormGroup>
            <Label check>{t("type")}</Label>
            <Field
              name="type"
              as="select"
              className={`form-control ${
                submitErrors && `${errors.type ? "is-invalid" : "is-valid"}`
              }`}
            >
              <option value=""></option>
              <option value="credit">{t("credit")}</option>
              <option value="debit">{t("debit")}</option>
            </Field>
            <ErrorMessage
              name="type"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12" md="12">
          <FormGroup>
            <Label check>{t("description")}</Label>
            <Field
              name="description"
              type="text"
              className={`form-control ${
                submitErrors &&
                `${errors.description ? "is-invalid" : "is-valid"}`
              }`}
              placeholder={t("description")}
            />
            <ErrorMessage
              name="description"
              component="span"
              className="invalid-feedback"
            />
          </FormGroup>
        </Col>
        <Col xs="12">
          <Btn
            color="primary"
            type="submit"
            onClick={() => setSubmitError(true)}
            disabled={loading}
          >
            {loading ? t("loading") : t("submit")}
          </Btn>
        </Col>
      </Row>
    </Form>
  );
};

export default FormValidations;
