import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H3, Image, P } from "../../AbstractElements";
import { dynamicImage } from "../../Service";
import { Href } from "../../utils/Constant";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Language from "../../Layout/Header/RightHeaderIcon/Language/Language";
import { login } from "../../Service/auth";

const Login = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const SimpleLoginHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await login({ email, password });
      if (response.token) {
        localStorage.setItem(
          "M2C:userData",
          JSON.stringify({
            token: response.token,
            userInfo: response.user,
            isLoggedIn: true,
          })
        );

        navigate(`${process.env.PUBLIC_URL}/dashboard`);
      } else {
        toast.error(t("error_login"));
      }
    } catch (error) {
      toast.error(t("error_login"));
    }
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card login-dark">
            <div>
              <div>
                <Link className="logo text-center" to={Href}>
                  <Image
                    className="img-fluid for-light"
                    src={dynamicImage("logo/logo-1.png")}
                    alt="looginpage"
                  />
                  <Image
                    className="img-fluid for-dark"
                    src={dynamicImage("logo/logo.png")}
                    alt="looginpage"
                  />
                </Link>
              </div>
              <div className="login-main">
                <Form
                  className="theme-form"
                  onSubmit={(e) => SimpleLoginHandle(e)}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <H3>{t("sig_in_text")}</H3>
                      <P>{t("enter_email")}</P>
                    </div>
                    <Language />
                  </div>
                  <FormGroup>
                    <Label className="col-form-label">
                      {t("email_addres")}
                    </Label>
                    <Input
                      type="email"
                      required
                      placeholder="Test@gmail.com"
                      value={email}
                      name="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{t("password")}</Label>
                    <div className="form-input position-relative">
                      <Input
                        type={show ? "text" : "password"}
                        placeholder="*********"
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                        name="password"
                      />
                      <div className="show-hide" onClick={() => setShow(!show)}>
                        <span className="show"> </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-0 form-sub-title">
                    <Link to={`${process.env.PUBLIC_URL}/ForgetPassword`}>
                      {t("forgot_password")}
                    </Link>
                    <div className="text-end mt-3">
                      <Btn color="primary" block className="w-100">
                        {t("sig_in")}
                      </Btn>
                    </div>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
