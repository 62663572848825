import axios from "axios";
import store from "../ReduxToolkit/Store";

const API_URL = store.getState().apiUrl.url;

const getToken = () => {
  const userData = JSON.parse(localStorage.getItem("M2C:userData") || "{}");
  return userData?.token;
};

const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("M2C:userData");
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    return Promise.reject(error);
  }
);

const createGateway = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_URL}/gateways`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error creating Gateway", error);
    throw error;
  }
};

const listGateways = async (): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/gateways`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Gateways", error);
    throw error;
  }
};

const listGatewaySelect = async (): Promise<any[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/gateways/get-many`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Gateways", error);
    throw error;
  }
};

const viewGateway = async (identifier: string | undefined): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL}/gateways/${identifier}`,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error viewing Gateway", error);
    throw error;
  }
};

const updateGateway = async (identifier: string, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${API_URL}/gateways/${identifier}`,
      data,
      getAuthHeaders()
    );
    return response.data;
  } catch (error) {
    console.error("Error updating Gateway", error);
    throw error;
  }
};

const deleteGateway = async (
  identifier: string | undefined
): Promise<boolean> => {
  try {
    const response = await axios.delete(
      `${API_URL}/gateways/${identifier}`,
      getAuthHeaders()
    );
    return response.status === 204;
  } catch (error) {
    console.error("Error deleting Gateway", error);
    throw error;
  }
};

export {
  createGateway,
  listGateways,
  listGatewaySelect,
  viewGateway,
  updateGateway,
  deleteGateway,
};
